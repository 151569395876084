export const fetchData = (url, { body = null, method = 'GET', headers = {} } = {}) => {
    return fetch(url, {
        method,
        headers, 
        body,
    })
    .then(res => {
        console.log("RATO", res.ok)
        if(res.ok) return res.json();
        return res.json().then(json => Promise.reject({ status: res.status, json }));
    })
    .catch(err=>{
        console.log("AQAA", err)
        if(err.status === 401){
            window.location.href = '/login';
            localStorage.removeItem('token')
        }
        const errMessage = err.json.message || err.json.error
        alert(errMessage);
        throw err.json;
    })
};