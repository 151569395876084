import { fetchData } from 'app/services/fetchService';
import config from 'config';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';


const List_Item = ({product = {}}) => {
    const { i18n } = useTranslation();
        const [salePercentage, setSalePercentage] = useState(null);
    
        useEffect(()=>{
            if(product.salePrice){
                setSalePercentage(product.salePrice);
                return;
            }
            getSale()
        },[])


        const getSale =()=>{
            fetchData(`${config.apiUrl}/sale`)
            .then((percentage)=>{
                if(percentage){
                    setSalePercentage(percentage.percentage)
                }
            })
        }

        function getDiscountedPrice(amount, salePercentage) {
            const discountedPrice = amount - (amount * salePercentage / 100);
            return discountedPrice.toFixed(2);
        }
    
    
    return ( 
    <div className='listItem'>
        <Link to={`item/${product._id}`}>
            <div className='image__wrap'>
                <img src={product.images && product.images[0]} alt="Shop Item" />
                {salePercentage ? <span className='salePrice'>-{salePercentage}%</span> : null}
            </div>
            <div className='item--info'>
                <p className='itemName'>{i18n.language==="en" ? product.name : product.name__french}</p>
                <p className='itemBrand'>{i18n.language==="en" ? product.brand : product.brand__french}</p>
                <p className='itemPrice'>
                    {salePercentage ? (
                        <>
                            <del>{product.price} CHF</del> {getDiscountedPrice(product.price, salePercentage)} CHF
                        </>
                        ) : (
                    `${product.price} CHF`)} 
                </p>
                
            </div>
        </Link>
    </div>
     );
}
 
export default List_Item;