import WelcomeImage from 'images/welcome/mainImage.webp';
import WelcomeImageMobile from 'images/welcome/mainImage-mobile.webp';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import config from 'config';



const Welcome = () => {
    const [desktopImg, SetMainImg] = useState(null);
    const [mobileImage, SetMobileImg] = useState(null);

    const { t } = useTranslation();
    const navigate = useNavigate();

    const shopNow = ()=>{
        navigate('/shop');
    }

    useEffect(()=>{
        getDesktopImage();
        getMobileImage();
    },[])

    const getDesktopImage = ()=>{
        fetch(`${config.apiUrl}/images`)
        .then((response) => response.blob())
        .then((blob)=>{
            const objectURL = URL.createObjectURL(blob); // Create a Blob URL
            SetMainImg(objectURL);
        })
    }

    const getMobileImage = ()=>{
        fetch(`${config.apiUrl}/images/mobile`)
        .then((response) => response.blob())
        .then((blob)=>{
            const objectURL = URL.createObjectURL(blob); // Create a Blob URL
            SetMobileImg(objectURL);
        })
    }

    return ( 
        <section id="welcome">
            <picture>
                <source media='(min-width:992px)' srcSet={desktopImg}/>
                <img src={mobileImage} alt="" />
            </picture>
            <div className='welcome--overaly'>
                <div className='welcome--wrap container'>
                    <div className='welcome--text'>
                        <h1 className='font2'>{t('welcome_header')}</h1>
                        <p>{t('welcome_text')}</p>
                        <button onClick={shopNow}>{t('shop_now')}</button>
                    </div>
                </div>
            </div>
        </section>
     );
}
 
export default Welcome;