import { useEffect, useRef, useState } from 'react';
import InfoExpanderV2 from 'app/SharedComponents/InfoExpanderV2';
import { useParams } from 'react-router-dom';
import { CartContext } from 'app/Context/CartContext';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import keyboardUp from 'images/icons/keyboard_up.svg';
import keyboardDown from 'images/icons/keyboard_down.svg';
import { colors, materials } from 'globalVariables/globalVariables';
import config from 'config';
import { fetchData } from 'app/services/fetchService';


const ShopItem = () => {
    const [loadedImg, setImage] = useState('');
    const [salePercentage, setSalePercentage] = useState(null);

    const { i18n, t } = useTranslation();

    const { dispatch } = useContext(CartContext);

    const { id } = useParams();
    const [product, setProduct] = useState({});

    useEffect(()=>{
        fetchData(`${config.apiUrl}/products/${id}`)
        .then(product=>{
            setProduct(product);
            setImage(product.images[0]);
            if(product.salePrice){
                setSalePercentage(product.salePrice);
                return;
            }
            getSale();
        })

    },[id])

    const handleBuy = ()=>{
        product.salePrice = product.salePrice || salePercentage;
        dispatch({ type: 'ADD_TO_CART', payload: product });
    }

    const getSale =()=>{
        fetchData(`${config.apiUrl}/sale`)
        .then((percentage)=>{
            if(salePercentage)return;
            if(percentage){
                setSalePercentage(percentage.percentage)
            }
        })
    }




    const scrollRef = useRef(null);
    const [isTopDisabled, setIsTopDisabled] = useState(true);
    const [isBottomDisabled, setIsBottomDisabled] = useState(false);
  
    const handleScrollUp = () => {
      if (scrollRef.current) {
        const isHorizontal = window.innerWidth <= 768; // Adjust breakpoint as needed
        const scrollAmount = isHorizontal ? -100 : -100;
        scrollRef.current.scrollBy({ [isHorizontal ? 'left' : 'top']: scrollAmount, behavior: 'smooth' });
        setTimeout(updateButtonState, 300); // Ensure buttons are updated after scrolling
      }
    };
  
    const handleScrollDown = () => {
      if (scrollRef.current) {
        const isHorizontal = window.innerWidth <= 768; // Adjust breakpoint as needed
        const scrollAmount = isHorizontal ? 100 : 100;
        scrollRef.current.scrollBy({ [isHorizontal ? 'left' : 'top']: scrollAmount, behavior: 'smooth' });
        setTimeout(updateButtonState, 300); // Ensure buttons are updated after scrolling
      }
    };
  
    const updateButtonState = () => {
      if (scrollRef.current) {
        const { scrollTop, scrollHeight, clientHeight, scrollLeft, scrollWidth, clientWidth } = scrollRef.current;
        const isHorizontal = window.innerWidth <= 768; // Adjust breakpoint as needed
        setIsTopDisabled(isHorizontal ? scrollLeft === 0 : scrollTop === 0);
        setIsBottomDisabled(isHorizontal ? scrollLeft + clientWidth === scrollWidth : scrollTop + clientHeight === scrollHeight);
      }
    };

    function getDiscountedPrice(amount, salePercentage) {
        const discountedPrice = amount - (amount * salePercentage / 100);
        return discountedPrice.toFixed(2);
    }

    return ( 
    <section id="shopItem" className="container">
            <div className="item--wrap">
                <div className="item--images">
                    <div className='item--image--box'>
                        <div className='item--image--main'>
                            <img src={loadedImg} alt="" />
                            {salePercentage ? <span className='salePrice'>-{salePercentage}%</span> : null}
                            </div>
                        <div className="item--image--carousel">

                            <img className={`icon up ${isTopDisabled && "disabled"}`} src={keyboardUp} alt=""  onClick={handleScrollUp}/>
                            <div className='scrollable' ref={scrollRef} onScroll={updateButtonState}>
                                {
                                    product.images && product.images.map((image, index)=>(
                                        <img key={index} src={image} alt="product" onClick={()=>{setImage(image)}} />
                                    ))
                                }
                            </div>
                            <img className={`icon down ${isBottomDisabled && "disabled"}`} src={keyboardDown} alt=""  onClick={handleScrollDown}/>
                        </div>
                    </div>
                </div>

                <div className='item--info'>
                    <h2 className='item--name'>{product.name}</h2>
                    <div className='item--details'>
                        <p><span className='details--key'>{t('Size')} </span> <span className='details--value'>{product.size}</span></p>
                        <p><span className='details--key'>Brand: </span> <span className='details--value'>{i18n.language === "en" ? product.brand : product.brand__french}</span></p>
                        {
                            product && 
                            (
                                <>
                                    <p><span className='details--key'>{t('color')} </span> <span className='details--value'>{i18n.language === "en" ? product.color : colors.find(color=>color.en === product.color)?.fr}</span></p>
                                    <p><span className='details--key'>{t('Material')} </span> <span className='details--value'>{i18n.language === "en" ? product.material : materials.find(material=>material.en === product.material)?.fr}</span></p>
                                </>
                            )
                        }
                        <p><span className='details--key'>Details: </span> <span className='details--value'>{i18n.language === "en" ? product.description : product.description__french}</span></p>
                    </div>
                    <p className='item--price'>
                        {salePercentage ? (
                        <>
                            <del>{product.price} CHF</del> {getDiscountedPrice(product.price, salePercentage)} CHF
                        </>
                        ) : (
                        `${product.price} CHF`)}                    
                    </p>
                    <button className='item--buy' onClick={handleBuy}>{t('add_to_cart')}</button>


                    {
                        (product.dimensions && product.dimensions.length) ? 
                        (<InfoExpanderV2 title={t('Exact_dimensions')}>
                            <div className='expander-content-text'>
    
                                {
                                        product.dimensions.map((dimension, index)=>(
                                        <React.Fragment key={index}>
                                            <p className='property--title'>{i18n.language === "en" ? dimension.dimension__name : dimension.dimension__name_fr}</p>
                                            <p className='item--dimensions'>
                                                <span>{t('Waist')} {dimension.dimension__waist}</span>
                                                <span>{t('Length')} {dimension.dimension__length}</span>
                                                <span>{t('Shoulders')} {dimension.dimension__shoulders}</span>
                                            </p>
                                        </React.Fragment>
                                    ))
                                    
                                }
                            </div>
                        </InfoExpanderV2>)
                        : null
                    }
                </div>
            </div>
    </section> 
    );
}
 
export default ShopItem;