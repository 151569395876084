import TermsEn from 'images/terms/Terms_EN-1.webp';
import TermsEn2 from 'images/terms/Terms_EN-2.webp';
import TermsFR from 'images/terms/Terms_FR-1.webp';
import TermsFR2 from 'images/terms/Terms_FR-2.webp';
import TermsFR3 from 'images/terms/Terms_FR-3.webp';
import { useTranslation } from 'react-i18next';



const TermsPage = () => {

    const { i18n, t } = useTranslation();
    

    return ( 
    <section id="termsPage" className="container">

        {
            i18n.language === "en" ? 
            <>
                <img src={TermsEn} alt="" />
                <img src={TermsEn2} alt="" />
            </> : 
            <>
                <img src={TermsFR} alt="" />
                <img src={TermsFR2} alt="" />
                <img src={TermsFR3} alt="" />
            </>
        }
    </section> 
    );
}
 
export default TermsPage;