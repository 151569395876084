import AppButton from "app/SharedComponents/App_Button";
import { fetchData } from "app/services/fetchService";
import config from "config";
import { useEffect, useRef, useState } from "react";

const AddCatalogue = ({onPostSuccess, isMobile}) => {
    const fileInputRef = useRef(null);
    const [selectedImage, setSelectedImage] = useState(null);
    const [loading, setLoading] = useState(false);


    useEffect(()=>{
      getImage();
    },[])


    const uploadCatalogue = async (event)=>{
        event.preventDefault();
        setLoading(true)


        const url = `${config.apiUrl}/images/upload`;
        const accessToken = localStorage.getItem('token');
        const formData = new FormData(event.target);


        await fetchData(url, {
            method: 'POST', 
            headers:{
              'Authorization': `Bearer ${accessToken}`,
            },
            body: formData
          })
          .then(()=>{
            event.target.reset();
            setSelectedImage(null);
            onPostSuccess();
          })
          .catch((e)=>{
            console.log(e);
          }).finally(()=>{
            setLoading(false);
          })
    }

    const mobileImageUpload = async (event)=>{
      event.preventDefault();
      setLoading(true)


      const url = `${config.apiUrl}/images/upload/mobile`;
      const accessToken = localStorage.getItem('token');
      const formData = new FormData(event.target);


      await fetchData(url, {
          method: 'POST', 
          headers:{
            'Authorization': `Bearer ${accessToken}`,
          },
          body: formData
        })
        .then(()=>{
          event.target.reset();
          setSelectedImage(null);
          onPostSuccess();
        })
        .catch((e)=>{
          console.log(e);
        }).finally(()=>{
          setLoading(false);
        })
  }

    const getImage = async ()=>{
      const url = `${config.apiUrl}/images`;

      await fetch(url);
    }

    const addImage = ()=>{
        fileInputRef.current.click();
    }

    const handleFileChange = (event) => {
        if (event.target.files && event.target.files[0]) {
            const imageFile = event.target.files[0];
            setSelectedImage(URL.createObjectURL(imageFile));
          }
      };

    return ( 
        <div className="addCatalogue">
            <h3>{isMobile ? 'MOBILE' : 'DESKTOP'}</h3>
            <form action="" onSubmit={isMobile ? mobileImageUpload : uploadCatalogue}>
                <div className="imageContainer" onClick={addImage}>
                    <img src={selectedImage} alt=""/>
                    <input ref={fileInputRef} type="file" name="image" onChange={handleFileChange} />
                </div>
                <AppButton loading={loading}>Upload</AppButton>
            </form>
        </div>
     );
}
 
export default AddCatalogue;