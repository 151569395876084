import logo from 'images/icons/logo.svg';
import { NavLink } from 'react-router-dom';

const AdminHeader = () => {
    return ( 
        <header id="adminHeader">
            <div className='container'>
                <NavLink to={''}><img src={logo} alt="Logo" /></NavLink>

                <nav>
                    <ul>
                        <li><NavLink to={'itemList'}>ITEMS</NavLink></li>
                        <li><NavLink to={'itemList/sold'}>SOLD</NavLink></li>
                        {/* <li><NavLink>STATISTICS</NavLink></li> */}
                        {/* <li><NavLink to={'orders'}>Orders</NavLink></li> */}
                        <li><NavLink to={'catalogue'}>Main IMG</NavLink></li>
                        <li><NavLink to={'sale'}>Sale</NavLink></li>
                    </ul>
                </nav>

                <div className='headerInfo'>
                    
                </div>
            </div>
        </header>
     );
}
 
export default AdminHeader;