import Founder from "images/ourStory/founderImage.webp";
import { useState } from "react";
import { storyData } from "globalVariables/our_story";
import { useTranslation } from "react-i18next";
import ImageComponent from "app/SharedComponents/ImageComponent";
import keyboardDown from 'images/icons/keyboard_down.svg';

const OurStory = () => {
  const [activeCell, changeStory] = useState(0);
  const { i18n, t } = useTranslation();



  const onLeft = ()=>{
    const len = storyData.length;
    let dest = activeCell - 1;
    dest = dest < 0 ? len-1 : dest;
    changeStory(dest)
  }

  const onRight = ()=>{
    const len = storyData.length;
    let dest = activeCell + 1;
    dest = dest > len-1 ? 0 : dest;
    changeStory(dest)
  }


  return (
    <section id="ourStory" className="container">
      <h1 className="font2">{t('Our Story')}</h1>

      <div className="ourStory__list">
        {storyData.map((storyInfo, index) => (
          <div
            key={index}
            className={`ourStory__cell ${activeCell === index ? "active" : ""}`}
          >
            <p className="ourStory__date textGreen">{storyInfo.date}</p>
            <div className="ourStory_mainImage">
              <ImageComponent src={storyInfo.image} blurhash={storyInfo.blurhash}/>
              <img onClick={onLeft} className={`icon left`} src={keyboardDown} alt="a"/>
              <img onClick={onRight} className={`icon right`} src={keyboardDown} alt="a"/>
            </div>
            <p className="ourStory__text">{i18n.language ==="en" ? storyInfo.text : storyInfo.textFr}</p>
          </div>
        ))}
      </div>
      <div className="ourStory__picker__wrapper">
        <div className="ourStory__picker">
          {storyData.map((storyInfo, index) => (
            <div className="picker__border" key={index} onClick={() => {changeStory(index);}}>
              <div className="picker__imageContainer">
                <ImageComponent src={storyInfo.image} blurhash={storyInfo.blurhash}/>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="word__from__founder">
        <div className="text__wrap">
          <h2 className="title font2">{t('Word_from_the_founder')}</h2>
          <div className="text__main">
            <p className="bigText">
             {t('founder_text_main')}
            </p>
            <p className="smallText">
              {t('founder_text_second')}
            </p>

            <p className="smallText">
              {t('founder_text_third')}
            </p>
          </div>
        </div>
        <div className="founderImage__container">
          <ImageComponent src={Founder} blurhash={'LhK,yx~W%#Nd%2kCtQRkIVRja{bG'}/>
        </div>
      </div>
    </section>
  );
};

export default OurStory;
