import AddCatalogue from "./addCatalogue";

const AdminCatalogue = () => {

  const getAllCatalogues = () => {

  };





  return (
    <div className="adminCatalogue container">

      <AddCatalogue onPostSuccess={getAllCatalogues}></AddCatalogue>

      <AddCatalogue onPostSuccess={getAllCatalogues} isMobile={true}></AddCatalogue>
    </div>
  );
};

export default AdminCatalogue;
