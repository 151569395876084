import { fetchData } from "app/services/fetchService";
import config from "config";
import { useEffect, useState } from "react";

const Sale = () => {
    const [inputValue, setInputValue] = useState("");
    const [salePercentage, setSalePercentage] = useState(null);
    

    useEffect(()=>{
        getSale();
    },[])


    const getSale =()=>{
        fetchData(`${config.apiUrl}/sale`)
        .then((percentage)=>{
            if(percentage){
                setSalePercentage(percentage.percentage)
            }
        })
    }

    const saleClick = ()=>{
        if(inputValue > 100 || inputValue < 0 || isNaN(inputValue)){
            alert("Incorrect percentage");
            return;
        }

        fetchData(`${config.apiUrl}/sale`, 
            {
                method: 'POST', 
                body: JSON.stringify({percentage: inputValue}),
                headers: {
                    "Content-Type": "application/json"
                }
            })
            .then(()=>{
                setSalePercentage(inputValue)
            })
    }
    return ( 
        <div className="salePage">
            <h2>SALE</h2>
            <p>current Sale: {salePercentage} %</p>
            <input
                type="text"
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                placeholder="Type something..."
            />
            <button onClick={saleClick}>Set Sale</button>
        </div>
     );
}
 
export default Sale;