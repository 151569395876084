import La_Plongeuse_desktop_Img from 'images/plongeuse/La_Plongeuse_desktop.webp';
import La_Plongeuse_mobile_Img from 'images/plongeuse/La_Plongeuse_mobile.webp';
import Heartshaped_cutout_top from 'images/Plongues_bases/Heartshaped_cutouttop.png';
import Halter_top_IPSF from 'images/Plongues_bases/Halter_top_IPSF.png';
import Scoopneck_Top from 'images/Plongues_bases/scoopnep_top.png';
import Seemless_Front_Shorts from 'images/Plongues_bases/seemless front shorts.png';
import IPSF_Shorts from 'images/Plongues_bases/IPSF Shorts.png';
import Cheeky_Bottoms from 'images/Plongues_bases/Cheeky bottoms.png';
import Short_Sleeved_Catsuit from 'images/Plongues_bases/Short  Sleeved catsuit.png';
import Longsleeved_Catsuit from 'images/Plongues_bases/Longsleeved catsuit.png';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';

const La_Plongeuse  = () => {
  const myRef = useRef(null)
  const {t} = useTranslation();
  const executeScroll = () => myRef.current.scrollIntoView()


    return (
      <section id="La_Plongeuse">
        <div className="landingImage">
          <div className="landingImage--info">
            <div className="landingImage--text">
              <h1>
                {t('plonges_header').split('\n').map((item, index) => (
                <span className={index===0 ? 'font2' : null} key={index}>
                  {item}
                  <br />
                </span>
                ))}
              </h1>
              <button onClick={executeScroll}>{t('choose_base')}</button>
            </div>
          </div>
          <picture>
            <source
              media="(min-width:768px)"
              srcSet={La_Plongeuse_desktop_Img}
            />
            <img src={La_Plongeuse_mobile_Img} alt="" />
          </picture>
        </div>

        <div ref={myRef} className="plongues--bases container">
          <h2 className='font2'>{t('our_bases')}</h2>

          <div className="plongues--set">
            <p className="plongues--set--title font2">{t('tops')}</p>
            <div className="plongues--set--flex">
              <div className="plongues--set--cell">
                <img
                  className="plongues--set--img"
                  src={Heartshaped_cutout_top}
                  alt=""
                />
                <div className="plongues--cell-info">
                  <p className="plongues--cell-name">{t('Heartshaped_cutout_top')}</p>
                  <p className="plongues--cell-price">140CHF</p>
                </div>
              </div>
              <div className="plongues--set--cell">
                <img
                  className="plongues--set--img"
                  src={Halter_top_IPSF}
                  alt=""
                />
                <div className="plongues--cell-info">
                  <p className="plongues--cell-name">{t('Halter_top_IPSF')}</p>
                  <p className="plongues--cell-price">120CHF</p>
                </div>
              </div>
              <div className="plongues--set--cell">
                <img
                  className="plongues--set--img"
                  src={Scoopneck_Top}
                  alt=""
                />
                <div className="plongues--cell-info">
                  <p className="plongues--cell-name">{t('Scoopneck_Top')}</p>
                  <p className="plongues--cell-price">100CHF</p>
                </div>
              </div>
            </div>
          </div>

          <div className="plongues--set">
            <p className="plongues--set--title font2">Shorts</p>
            <div className="plongues--set--flex">
              <div className="plongues--set--cell">
                <img
                  className="plongues--set--img"
                  src={Seemless_Front_Shorts}
                  alt=""
                />
                <div className="plongues--cell-info">
                  <p className="plongues--cell-name">{t('seemless_front_shorts')}</p>
                  <p className="plongues--cell-price">100CHF</p>
                </div>
              </div>
              <div className="plongues--set--cell">
                <img className="plongues--set--img" src={IPSF_Shorts} alt="" />
                <div className="plongues--cell-info">
                  <p className="plongues--cell-name">{t('IPSF_Shorts')}</p>
                  <p className="plongues--cell-price">100CHF</p>
                </div>
              </div>
              <div className="plongues--set--cell">
                <img
                  className="plongues--set--img"
                  src={Cheeky_Bottoms}
                  alt=""
                />
                <div className="plongues--cell-info">
                  <p className="plongues--cell-name">{t('Cheeky_bottoms')}</p>
                  <p className="plongues--cell-price">90 CHF</p>
                </div>
              </div>
            </div>
          </div>

          <div className="plongues--set">
            <p className="plongues--set--title font2">{t('Catsuits')}</p>
            <div className="plongues--set--flex plongues--set--flex--center">
              <div className="plongues--set--cell">
                <img
                  className="plongues--set--img"
                  src={Short_Sleeved_Catsuit}
                  alt=""
                />
                <div className="plongues--cell-info">
                  <p className="plongues--cell-name">{t('Short_Sleeved_catsuit')}</p>
                  <p className="plongues--cell-price">100CHF</p>
                </div>
              </div>
              <div className="plongues--set--cell">
                <img
                  className="plongues--set--img"
                  src={Longsleeved_Catsuit}
                  alt=""
                />
                <div className="plongues--cell-info">
                  <p className="plongues--cell-name">{t('Longsleeved_catsuit')}</p>
                  <p className="plongues--cell-price">100CHF</p>
                </div>
              </div>
            </div>
          </div>

          {/* <div className="plongues--optional">
            <p className="optional--title">
              <img src={Bulavka} alt="icon" />
              <span className="title--optional">{t('Optional')}</span>
              <span className="textGreen">{t('Optional_price')}</span>
            </p>

            <div className="optional--info">
              <p className="info--icon">Info:</p>
              <p className="info--text">
                {t('Optional_info')}
              </p>
            </div>
          </div> */}
        </div>

        <div className="workProcess--title container">
            <h3 className='font2'>{t('work_with_us_process')}</h3>
            {/* <p>
              {t('work_with_us_process_info')}
            </p> */}
        </div>

        <div className="workProcess container">

          <div className="workProcess--steps">
            <div className="steps--cell">
              <p className="textGreen stepNumber">01</p>
              <p className="stepName">{t('first_meeting')}</p>
              <p className="stepInfo">
                {t('first_meeting_text')}
              </p>
            </div>
            
            <div className="steps--cell">
              <p className="textGreen stepNumber">02</p>
              <p className="stepName">{t('first_fitting')}</p>
              <p className="stepInfo">
                {t('first_fitting_text')}
              </p>
            </div>

            <div className="steps--cell">
              <p className="textGreen stepNumber">03</p>
              <p className="stepName">{t('decoration')}</p>
              <p className="stepInfo">
                {t('decoration_text')}
              </p>
            </div>

            <div className="steps--cell">
              <p className="textGreen stepNumber">04</p>
              <p className="stepName">{t('final_fitting')}</p>
              <p className="stepInfo">
                {t('final_fitting_text')}
              </p>
            </div>
            
          </div>

        <div className='someNewText'>
        <p>{t('handmade_costume')}</p>
        </div>

        </div>
      </section>
    );
}
 
export default La_Plongeuse ;